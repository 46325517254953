<template>
    <div class="p-pegas">
        <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
            <div class="card-table-body ifon-add-title-block">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="caz-blocks-sarcho-title">
                            <div class="content-title d-flex align-center mr-2" :class="mode ? 'content__titleday' : 'content__titlenight'">
                                {{ $t("message.telegram_quiz") }}
                            </div>
                            <div class="block-sarche">
                                <div class="header__search">
                                    <crm-input
                                        :size="'small'"
                                        :className="'w100'"
                                        :class="mode ? 'input__day' : 'input__night'"
                                        v-model="filterForm.search"
                                        :icon="'el-icon-search'"
                                    ></crm-input>
                                </div>
                            </div>
                            <!-- <div class="block-sarche ml-2">
                              <select-branch  
                                :size="'small'"
                                :class="mode ? 'input__day' : 'input__night'"
                                :id="filterForm.branch_id"
                                v-model="filterForm.branch_id" >
                              </select-branch>
                            </div> -->
                        </div>
                    </el-col>

                    <el-col :span="12" class="flex-style text-right">
                        <crm-create-and-column-settings
                            :permission="$options.name"
                            :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                            @c-create="drawerCreate = true"
                            :columns="columns"
                            @c-change="updateColumn"
                            :v_can_create="'quiz_plans.create'"
                        >
                        </crm-create-and-column-settings>
                    </el-col>
                </el-row>
            </div>
            <!-- end ifon-add-title-block -->

            <div class="card-table-header table-crm-smart">
                <table
                    :class="mode ? 'table__myday' : 'table__mynight'"
                    class="table-my-code table-bordered"
                    v-loading="loadingData"
                >
                    <thead>
                        <tr>
                            <th class="w50p" v-if="columns.id.show">
                                {{ columns.id.title }}
                            </th>

                            <th v-if="columns.staff.show">
                                {{ columns.staff.title }}
                            </th>

                            <th v-if="columns.started_date.show">
                                {{ columns.started_date.title }}
                            </th>
                            <th v-if="columns.end_date.show">
                              {{ columns.end_date.title }}
                            </th>
                            <th v-if="columns.point.show">
                              {{ columns.point.title }}
                            </th>
                            <th v-if="columns.status.show">
                              {{ columns.status.title }}
                            </th>
                            <th v-if="columns.created_at.show">
                              {{ columns.created_at.title }}
                            </th>
                            <th v-if="columns.updated_at.show">
                                {{ columns.updated_at.title }}
                            </th>

                            <th v-if="columns.settings.show">
                                {{ columns.settings.title }}
                            </th>
                        </tr>

                        <tr class="filter_sorche">
                            <th v-if="columns.id.show">
                                <el-input
                                    clearable
                                    size="mini"
                                    v-model="filterForm.id"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="columns.id.title"
                                    class="id_input"
                                ></el-input>
                            </th>

                            <th v-if="columns.staff.show">
                              <select-staff
                                :size="'mini'"
                                v-model="filterForm.staff_id"
                                :class="mode ? 'input__day' : 'input__night'"
                                :id="filterForm.staff_id">
                              </select-staff>
                            </th>
                            <th v-if="columns.started_date.show">
                              <crm-date-picker
                                  :placeholder="columns.started_date.title"
                                  :class="mode ? 'filter__day' : 'filter__night'"
                                  v-model="filterForm.start_date_time"
                              ></crm-date-picker>
                            </th>
                            <th v-if="columns.end_date.show">
                              <crm-date-picker
                                  :placeholder="columns.end_date.title"
                                  :class="mode ? 'filter__day' : 'filter__night'"
                                  v-model="filterForm.end_date_time"
                              ></crm-date-picker>
                            </th>
                            <th v-if="columns.point.show">
                              <crm-input  :class="mode ? 'input__day' : 'input__night'"
                                :inputValue="filterForm.point"
                                :placeholder="$t('message.point')"
                                v-model="filterForm.point"
                                size="mini"
                                :type="'number'"
                              ></crm-input>
                            </th>
                            <th v-if="columns.status.show">
                             
                            </th>
                            <th v-if="columns.created_at.show">
                              <crm-date-picker
                                  :placeholder="columns.created_at.title"
                                  :class="mode ? 'filter__day' : 'filter__night'"
                                  v-model="filterForm.created_at"
                              ></crm-date-picker>
                          </th>
                            <th v-if="columns.updated_at.show">
                                <crm-date-picker
                                    :placeholder="columns.updated_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.updated_at"
                                ></crm-date-picker>
                            </th>
                            <th
                                class="settinW"
                                v-if="columns.settings.show"
                            ></th>
                        </tr>
                    </thead>

                    <transition-group name="flip-list" tag="tbody">
                        <tr
                            v-for="telegram_quiz in list"
                            :key="telegram_quiz.id"
                            class="cursor-pointer"
                        >
                            <td v-if="columns.id.show">{{ telegram_quiz.id }}</td>
                            <td v-if="columns.name.show">
                              {{
                                telegram_quiz.staff
                                  ? telegram_quiz.staff.name + " " + telegram_quiz.staff.last_name
                                  : ""
                              }}
                            </td>
                            <td v-if="columns.from_date.show">
                              {{ telegram_quiz.start_date_time }}
                            </td>
                            <td v-if="columns.to_date.show">
                              {{ telegram_quiz.end_date_time }}
                            </td>
                            <td v-if="columns.daly_question_count.show">
                              {{ telegram_quiz.point }}
                            </td>

                            <td v-if="columns.daly_question_count.show">
                              {{ $t("message."+telegram_quiz.status) }}
                             
                            </td>
                            <td v-if="columns.created_at.show">
                                {{ telegram_quiz.created_at }}
                            </td>

                            <td v-if="columns.updated_at.show">
                                {{ telegram_quiz.updated_at }}
                            </td>
                            <td v-if="columns.settings.show">
                              <el-button @click="showInfoList(telegram_quiz.id)"  type="success" size="medium"><i class="fa-regular fa-eye"></i></el-button>
                            </td>
                            <!-- <td
                                v-if="columns.settings.show"
                                class="settings-td"
                            >
                                <crm-settings
                                    :name="$options.name"
                                    :model="telegram_quiz"
                                    :actions="actions"
                                    @edit="edit"
                                   
                                    @delete="destroy"
                                ></crm-settings>
                            </td> -->
                        </tr>
                    </transition-group>
                </table>
                <div class="my___pagination">
                    <crm-pagination
                        @c-change="updatePagination"
                        :class="mode ? 'pagination__day' : 'pagination__night'"
                        :pagination="pagination"
                    ></crm-pagination>
                </div>
            </div>

          
        </div>
        <el-drawer
          title="I'm outer Drawer"
          :visible.sync="showInfoModal"
          size="40%"
          :with-header="false"
          ref="show-info"
          @opened="drawerOpened('show-info')"
        >
          <div>
            <show-info
              :telegram_quiz="selected"
              @closeModal="closeModal"
              :child="child"
              ref="show-info"
            ></show-info>
          </div>
        </el-drawer>
    </div>
</template>
<script>
import list from "@/utils/mixins/list";
import { i18n } from "@/utils/i18n";
import showInfo from "./components/show-info.vue";

import { mapGetters, mapActions, mapState } from "vuex";
export default {
    name: "questionCategoryListController",
    mixins: [list],
    components: {
      showInfo
    },

    data() {
        return {
          child: {},
          showInfoModal: false,
          selected: null,
        };
    },
    computed: {
        ...mapGetters({
            list: "telegramQuiz/list",
            columns: "telegramQuiz/columns",
            pagination: "telegramQuiz/pagination",
            statues: "telegramQuiz/statues",
            filter: "telegramQuiz/filter",
            sort: "telegramQuiz/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "telegramQuiz/index",
            setPagination: "telegramQuiz/setPagination",
            updateSort: "telegramQuiz/updateSort",
            updateFilter: "telegramQuiz/updateFilter",
            updateColumn: "telegramQuiz/updateColumn",
            updatePagination: "telegramQuiz/updatePagination",
            show: "telegramQuiz/show",
            empty: "telegramQuiz/empty",
            delete: "telegramQuiz/destroy",
            refreshData: "telegramQuiz/refreshData",
        }),
        showInfoList(quiz_id) {
          this.showInfoModal = true;
          this.child.quiz_id = quiz_id;
        },
        closeModal(val) {
          this.showInfoModal = val;
        },
        drawerOpened(ref) {
          if (this.$refs[ref]) {
            if (_.isFunction(this.$refs[ref].opened)) {
              this.$refs[ref].opened(this.child);
            }
          }
        },
    },
};
</script>

