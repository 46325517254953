<template >
    <div>
        <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
            <div class="app-modal__in">
                <div
                    class="app-modal__header my-top-khan-title"
                >
                    <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">{{$t("message.telegram_quiz")}}</p>
                    <el-button @click="closeModal()" type="warning" plain>{{$t("message.close")}}</el-button>
                </div>
            </div>
            <div class="app-modal__body p-5 " v-loading="loadStaff">
               <div class="elemet-content">
                
                <div class="timeline-items__right user rounded-sm w-100 p-4" v-if="telegram_quiz_info&&telegram_quiz_info.staff"  @dblclick="pushToProfile(telegram_quiz_info.staff)" :class="mode ? 'table__myday' : 'table__mynight'">
                  <div class="info-user-left3" :class="mode ? '' : 'performance__itemnight'">
                    <div class="img">
                      <img
                        :src="
                          telegram_quiz_info.staff.image
                            ? baseUrl + telegram_quiz_info.staff.image.path
                            : 'img/avatar.jpeg'
                        "
                        alt="avatar"
                      />
                    </div>
                    <div class="name-info-user-left">
                      <b>{{ $t('message.staff') }}</b>
                      <div class="name_u">
                      <b>{{ $t('message.fio') }}: </b>{{ telegram_quiz_info.staff.name + " " + telegram_quiz_info.staff.last_name }}
                      </div>
                      <div class="company_u" v-if="telegram_quiz_info.staff.company" :class="mode ? 'text__day2' : 'text__night2'">
                        <b>{{ $t('message.company') }}: </b> {{ telegram_quiz_info.staff.company.name }}
                      </div>
                      <div class="company_u" v-if="telegram_quiz_info.staff.branch" :class="mode ? 'text__day2' : 'text__night2'">
                        <b>{{ $t('message.branch') }}: </b> {{ telegram_quiz_info.staff.branch.name }}
                      </div>
                      <div class="company_u" v-if="telegram_quiz_info.staff.department" :class="mode ? 'text__day2' : 'text__night2'">
                        <b>{{ $t('message.department') }}: </b>  {{ telegram_quiz_info.staff.department.name }}
                      </div>
                      <div class="company_u" v-if="telegram_quiz_info.staff.position" :class="mode ? 'text__day2' : 'text__night2'">
                        <b>{{ $t('message.position') }}: </b>{{ telegram_quiz_info.staff.position.name }}
                      </div>
                      <!-- <div class="salary">
                        <div><h4>Зарплата</h4></div>
                        <div>
                          <ul class="salary-list">
                            <li
                              v-for="(value, index) in staff.salary_amounts"
                              :key="'salary-' + index"
                            >
                              {{ value.toLocaleString() }} {{ index }}
                            </li>
                          </ul>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="timeline-items__right rounded-sm w-100 p-4"   :class="mode ? 'table__myday' : 'table__mynight'" v-if="telegram_quiz_info">
                  <b>Викторина: </b>{{ telegram_quiz_info.quiz_plan?telegram_quiz_info.quiz_plan.name:'' }} <br>
                  <b>Накопленные баллы: </b>{{ telegram_quiz_info.point }} <br>
                  <b>Время начала: </b> {{ telegram_quiz_info.start_date_time }} <br>
                  <b>Время окончания: </b>{{ telegram_quiz_info.end_date_time }} <br>
                  <el-divider>
                    <i class="fa-regular fa-star"></i>
                    Вопросы викторины
                    <i class="fa-regular fa-star"></i>
                  </el-divider>

                  <el-timeline>
                    <el-timeline-item 
                      :timestamp="answer.date+' '+answer.start_time" 
                      placement="top"  
                      v-for="(answer, index) in telegram_quiz_info.answers" 
                      :key="index+1"
                      :color="answer.option==null?'#dd8a15':(answer.point>0?'#0bbd87':'#ff6567')"
                      :icon="answer.option==null?'el-icon-magic-stick':(answer.point>0?'el-icon-check':'el-icon-close')"
                      :size="'large'"
                      >
                      <el-card>
                        <h4 class="my-1">{{index+1}}.  : {{answer.question.content}}</h4>
                        <!-- <p class="m-2 mx-3"><b>Ответ: </b>{{answer.option.title}}</p> -->
                        <p class="m-2 mx-3"><b>Балл: </b>{{answer.point}}</p>
                        <p class="m-2 mx-3"><b>Время реакции: </b>{{answer.response_time}} секунд</p>
                        <p class="m-2 mx-3" v-if="(answer.option==null)&&answer.answer_info&&answer.answer_info"><b>Ответ сотрудник: </b>
                          <a :href="baseUrl+answer.answer_info.user_answer" target="_blank"><b>Link</b></a>
                        </p>
                      </el-card>
                    </el-timeline-item>
                  </el-timeline>
                </div>
               </div>
            </div>
            <!-- end app-modal__body -->
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
// import closeDialog from "../../../utils/mixins/dialog"
import drawer from "@/utils/mixins/drawer";
export default {
    name: "show-info",
    mixins: [drawer],
    components: {},
    data() {
        return {
          loadStaff: false,
            baseUrl: process.env.VUE_APP_URL.substring(
              0,
              process.env.VUE_APP_URL.length - 4
            ),
        };
    },
    computed: {
    ...mapGetters({
        telegram_quiz_info: "telegramQuiz/telegram_quiz_info",
        mode: "MODE"
    }),
  },
  methods: {
    ...mapActions({
        getTelegramQuizInfo: "telegramQuiz/telegram_quiz_info",
    }),

    opened(child) {
      if (!this.loadStaff) {
        this.loadStaff = true;
        this.getTelegramQuizInfo(child)
          .then((res) => {
            this.loadStaff = false;
          })
          .catch((err) => {
            this.loadStaff = false;
          });
      }

    },
    pushToProfile(staff) {
      this.$router.push({
        name: "profile",
        params: { id: staff.id },
      });
    },
    closeModal() {
      this.$emit("closeModal", false);
    },
  }
};
</script>
<style lang="scss" >
.timeline-items__right.user {
  margin-bottom: 20px;
  .company_u{
    margin-bottom: 3px;
    font-size: 14px;
  }
  .info-user-left3 { 
    display: flex;
    align-items: start;
    justify-content: flex-start;
    flex-wrap: wrap;

    .img {
      margin-right: 10px;
      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 10%;
      }
    }
  }
  .name_u {
    font-weight: bold;
    font-size: 15px;
  }
  .position_u {
    color: #8a8a8a;
    font-size: 12px;
    font-weight: 300;
    margin-top: 2px;
  }
  .salary {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 5px;
  }
}
.title_and_button{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.timeline-items__right {
    margin-bottom: 20px;

    .info-user-left2 {
        display: flex;
        align-items: start;
        justify-content: flex-start;
        flex-wrap: wrap;

        .img {
            margin-right: 10px;
            img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
            }
        }
    }
    .name_u {
        font-weight: bold;
        font-size: 15px;
    }
    .position_u {
        color: #8a8a8a;
        font-size: 12px;
        font-weight: 300;
        margin-top: 2px;
    }
    .salary {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 5px;
    }
}
.info-user-left2{
    display: flex;
    align-items: center;
    //background-color: #fff;
    background-clip: border-box;
  //  border: 1px solid rgba(34,41,47,.1254901961);
    border-radius: 0.428rem;
    // box-shadow: 0 4px 24px 0 rgb(34 41 47 / 1%);
    width: 100%;
    cursor: pointer;
    padding: 10px;
}
.my-top-khan-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #dbdbdb;
    margin-bottom: 20px;
     flex-wrap: nowrap;
}
.info-user-left2{
  flex-wrap: nowrap !important;
}
.elemet-content{
  padding: 0 15px;
  height: 100%;
  overflow: auto;
}
.app-modal__box{
  min-height: 100vh;
      background-color: #eef2f4;
}
.el-drawer__body{
  overflow: scroll;
}
</style>
